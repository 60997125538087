import React from "react";
import theme from "theme";
import { Theme, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"kontakti"} />
		<Helmet>
			<title>
				Study Prosper Hub
			</title>
			<meta name={"description"} content={"Водійські права — це не лише документ, а й свобода пересування, нові можливості та впевненість у собі."} />
			<meta property={"og:title"} content={"Study Prosper Hub"} />
			<meta property={"og:description"} content={"Водійські права — це не лише документ, а й свобода пересування, нові можливості та впевненість у собі."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/67449b3428b5e30023ae6a02/images/Wright-Illustrations-adult-1110x734x0x0x1110x734x1680136260.png?v=2024-11-25T15:51:08.157Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/67449b3428b5e30023ae6a02/images/Wright-Illustrations-adult-1110x734x0x0x1110x734x1680136260.png?v=2024-11-25T15:51:08.157Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/67449b3428b5e30023ae6a02/images/Wright-Illustrations-adult-1110x734x0x0x1110x734x1680136260.png?v=2024-11-25T15:51:08.157Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/67449b3428b5e30023ae6a02/images/Wright-Illustrations-adult-1110x734x0x0x1110x734x1680136260.png?v=2024-11-25T15:51:08.157Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/67449b3428b5e30023ae6a02/images/Wright-Illustrations-adult-1110x734x0x0x1110x734x1680136260.png?v=2024-11-25T15:51:08.157Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/67449b3428b5e30023ae6a02/images/Wright-Illustrations-adult-1110x734x0x0x1110x734x1680136260.png?v=2024-11-25T15:51:08.157Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/67449b3428b5e30023ae6a02/images/Wright-Illustrations-adult-1110x734x0x0x1110x734x1680136260.png?v=2024-11-25T15:51:08.157Z"} />
		</Helmet>
		<Components.Header />
		<Components.Hero background="linear-gradient(180deg,rgba(3, 4, 48, 0.79) 0%,--color-primary 100%),--color-darkL2 url(https://uploads.quarkly.io/67449b3428b5e30023ae6a02/images/uroki-vozhdeniya-na-akpp-dnipro-1.jpg?v=2024-11-25T15:51:08.155Z) 50%/cover">
			<Override slot="button" display="none" />
			<Override slot="text3">
				<Strong>
					Як розпочати навчання?
				</Strong>
			</Override>
			<Override slot="text4">
				Зателефонуйте нам і ми допоможемо вам зробити перший крок до мрії стати впевненим водієм.
			</Override>
			<Override slot="box2" opacity="1" />
			<Override slot="text">
				Березнева вулиця, 3, Хмельницький
			</Override>
			<Override slot="text1">
				info@studyprosperhub.com.com
			</Override>
			<Override slot="text2">
				0681112011
			</Override>
		</Components.Hero>
		<Components.Footer>
			<Override slot="link5" />
			<Override slot="link6" />
			<Override slot="text2" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"673cdfe29710450018a47394"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\nhtml, body {\nscroll-behavior: smooth;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});